import type { RequestErrorCode } from "./error";

import { OOMOL_SERVER_BASE_URL } from "./constants";
import { ServerRequestError } from "./error";

export type OOMOLServerResponseWithError = {
  code: RequestErrorCode;
  error: string;
};

export async function requestOOMOLServer<TPayload, TResult>(
  action: string,
  payload?: TPayload,
  init?: Partial<RequestInit>
): Promise<TResult> {
  const headers = new Headers(init?.headers);
  headers.set("accept", "application/json, text/plain, */*");
  const config: RequestInit = { ...init, headers, credentials: "include" };

  if (payload) {
    config.body = JSON.stringify(payload);
    headers.set("content-type", "application/json");
  }

  const response = await fetch(`${OOMOL_SERVER_BASE_URL}/${action}`, config);

  if (response.status >= 400) {
    const data: OOMOLServerResponseWithError = await response.json();
    throw new ServerRequestError(data.code);
  }

  const data: TResult = await response.json();

  return data;
}

export async function post<TPayload, TResult>(
  action: string,
  payload?: TPayload,
  init?: Partial<RequestInit>
): Promise<TResult> {
  const res = await requestOOMOLServer<TPayload, TResult>(action, payload, {
    ...init,
    method: "POST",
  });

  return res;
}

export async function get<TResult>(
  action: string,
  init?: Partial<RequestInit>
): Promise<TResult> {
  const res = await requestOOMOLServer<undefined, TResult>(action, undefined, {
    ...init,
    method: "GET",
  });

  return res;
}
