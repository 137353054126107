export enum RoutePath {
  Root = "/",
  Overview = "/overview",
  Billing = "/billing",
  BillingHistory = "/billing/billing-history",
  PayHistory = "/billing/pay-history",
  Security = "/security",
  Project = "/project",
  ProjectDetail = "/project/detail",
  Domains = "/domains",
  Activity = "/activity",
  Signin = "/signin",
  SigninWithDeskTop = "/signin/:desktop",
  SecurityLog = "/security/security-log",
  AccountSecurity = "/security/accounts-security",
  AppLauncher = "/launcher",
}
