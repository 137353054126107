import styles from "./HomeRoot.module.scss";

import type { UIMatch } from "react-router-dom";

import { Analytics } from "@vercel/analytics/react";
import { Breadcrumb } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import { useTranslate } from "val-i18n-react";
import { Navigation } from "~/components/Navigation";
import { getUserProfile, logout } from "~/oomol-server-api";
import { errorTips } from "~/utils/errorTips";
import { useSafePromise } from "~/utils/hooks";

import { RoutePath } from "../constants";
import { SideNav } from "./SideNav";

export const HomeRoot = () => {
  const t = useTranslate();
  const sp = useSafePromise();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    nickname: "",
    avatarURL: "",
  });

  useEffect(() => {
    // OPTIMIZE: 临时方案，开发环境下不需要登录检测
    if (process.env.NODE_ENV === "development") {
      return;
    }
    const cookies = document.cookie.split(";").map(cookie => cookie.trim());
    const isSignedIn = cookies.some(cookie =>
      cookie.includes("oomol-signed-in")
    );

    if (!isSignedIn) {
      navigate(RoutePath.Signin);
    }
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = await getUserProfile();
      setUserInfo({
        nickname: userInfo.nickname,
        avatarURL: userInfo.url,
      });
    };
    void getUserInfo();
  }, []);

  const matches = useMatches() as UIMatch<unknown, { crumb: string }>[];
  const crumbs = useMemo(
    () =>
      matches
        .filter(match => Boolean(match.handle?.crumb))
        .map(match => {
          return {
            href: match.pathname,
            title: t(match.handle.crumb),
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(match.pathname);
            },
          };
        }),
    [matches]
  );

  const renderNav = () => {
    if (crumbs.length === 0) {
      return null;
    } else {
      return (
        <div className={styles["sub-nav-box"]}>
          <div className={styles["sub-nav"]}>
            <Breadcrumb items={crumbs} />
          </div>
        </div>
      );
    }
  };

  const handleSignout = async (): Promise<void> => {
    // TODO: add loading status
    try {
      await sp(logout());
      navigate(RoutePath.Signin);
    } catch (error) {
      errorTips(error);
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Navigation
          signout={handleSignout}
          avatarURL={userInfo.avatarURL}
          username={userInfo.nickname}
          profileName={userInfo.nickname}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.sidebar}>
          <div className={styles["sidebar-content"]}>
            <SideNav />
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles["inner-container"]}>
              {renderNav()}
              <div className={styles["inner-box"]}>
                <div className={styles["inner"]}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Analytics />
    </div>
  );
};
