import { OOMOL_SERVER_USER_V1 } from "./constants";
import { get, post } from "./utils";

export type SigninWithAuthResult = {
  authID: string;
  expireAt: number;
  expireTime: string;
};

export function getAuthID(): Promise<SigninWithAuthResult> {
  return get<SigninWithAuthResult>(`${OOMOL_SERVER_USER_V1.AUTH}/auth_id`);
}

export function logout(): Promise<void> {
  return post<never, void>(`${OOMOL_SERVER_USER_V1.AUTH}/logout`);
}

export type GetUserProfileResult = {
  email: string;
  nickname: string;
  uid: string;
  url: string;
};

export function getUserProfile(
  username?: string
): Promise<GetUserProfileResult> {
  return username
    ? get<GetUserProfileResult>(
        `${OOMOL_SERVER_USER_V1.USERS}/profile?username=${username}`
      )
    : get<GetUserProfileResult>(`${OOMOL_SERVER_USER_V1.USERS}/profile`);
}
