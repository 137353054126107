import styles from "./BarChart.module.scss";

import type { EChartsOption } from "echarts";

import * as echarts from "echarts";
import { useRef, useEffect } from "react";

interface BarChartProps {
  barOptions: EChartsOption;
}

export const BarChart = ({ barOptions }: BarChartProps) => {
  const barChartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const barChart = echarts.init(barChartRef.current, "dark");
    barChart.setOption(barOptions);
    const observer = new ResizeObserver(() => {
      barChart.resize();
    });
    observer.observe(barChartRef.current!);
    return () => {
      observer.disconnect();
    };
  }, []);
  return <div ref={barChartRef} className={styles.chart} />;
};
