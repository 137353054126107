import styles from "./UserAvatar.module.scss";

import type { MenuProps } from "antd";

import {
  BgColorsOutlined,
  ControlOutlined,
  DashboardOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import { useTranslate } from "val-i18n-react";

export interface UserAvatarProps {
  email?: string;
  avatarURL: string;
  username: string;
  profileName: string;
  signout: () => void;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  email,
  avatarURL,
  username,
  profileName,
  signout,
}) => {
  const t = useTranslate();
  const items: MenuProps["items"] = [
    {
      key: "info",
      type: "group",
      label: (
        <InfoBox
          avatarURL={avatarURL}
          username={username}
          email={email}
          profileName={profileName}
        />
      ),
    },
    {
      type: "divider",
    },
    {
      key: "theme",
      label: (
        <div className={styles.cell}>
          <div className={styles.left}>
            <BgColorsOutlined />
            <span className={styles.name}>{t("home.theme")}</span>
          </div>
        </div>
      ),
    },
    {
      key: "settings",
      label: (
        <div className={styles.cell}>
          <div className={styles.left}>
            <ControlOutlined />
            <span className={styles.name}>{t("home.settings")}</span>
          </div>
        </div>
      ),
    },
    {
      key: "logout",
      label: (
        <div className={styles.cell}>
          <div className={styles.left}>
            <LogoutOutlined />
            <span className={styles.name}>{t("home.log-out")}</span>
          </div>
        </div>
      ),
      onClick: () => signout(),
    },
    {
      type: "divider",
    },
    {
      key: "console",
      label: (
        <div className={styles.cell}>
          <div className={styles.left}>
            <DashboardOutlined />
            <span className={styles.name}>{t("home.console")}</span>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items }} placement={"bottomRight"}>
      <Avatar src={avatarURL} icon={<UserOutlined />} />
    </Dropdown>
  );
};

const InfoBox: React.FC<
  Pick<UserAvatarProps, "avatarURL" | "email" | "profileName" | "username">
> = ({ username, profileName, email, avatarURL }) => {
  return (
    <div className={styles["info-box"]}>
      <div className={styles["info-avatar"]}>
        <Avatar size={64} src={avatarURL} icon={<UserOutlined />} />
      </div>
      <div className={styles["info-name"]}>{username}</div>
      <div className={styles["info-email"]}>{email ?? profileName}</div>
    </div>
  );
};
