import styles from "./LineChart.module.scss";

import type { EChartsOption } from "echarts";

import * as echarts from "echarts";
import { useRef, useEffect } from "react";

interface LineChartProps {
  lineOptions: EChartsOption;
}
export const LineChart = ({ lineOptions }: LineChartProps) => {
  const lineChartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const lineChart = echarts.init(lineChartRef.current, "dark");
    lineChart.setOption(lineOptions);
    const observer = new ResizeObserver(() => {
      lineChart.resize();
    });
    observer.observe(lineChartRef.current!);
    return () => {
      observer.disconnect();
    };
  }, []);
  return <div ref={lineChartRef} className={styles.chart} />;
};
