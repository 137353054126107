import styles from "./ProjectDetail.module.scss";

import type { TabsProps } from "antd";

import { Tabs } from "antd";

import { ProjectAnalytics } from "./ProjectAnalytics";
import { ProjectOverview } from "./ProjectOverview";
import { ProjectSettings } from "./ProjectSettings";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Overview",
    children: <ProjectOverview />,
  },
  {
    key: "2",
    label: "Deployments",
    children: <Deployments />,
  },
  {
    key: "3",
    label: "Analytics",
    children: <ProjectAnalytics />,
  },
  {
    key: "4",
    label: "Logs",
    children: <Logs />,
  },
  {
    key: "5",
    label: "Settings",
    children: <ProjectSettings />,
  },
];

const onChange = (key: string) => {
  console.log(key);
};

export const ProjectDetail = () => {
  return (
    <div className={styles.box}>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

function Deployments() {
  return <div>Deployments</div>;
}

function Logs() {
  return <div>Logs</div>;
}
