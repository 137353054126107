import styles from "./Signin.module.scss";

import { Spin } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { RoutePath } from "../constants";
import { OOMOL_SERVER_SIGNIN_AUTH } from "../../oomol-server-api";

const Signin: React.FC = () => {
  const { desktop } = useParams();
  const navigate = useNavigate();

  const isSignedIn = (): boolean => {
    const cookies = document.cookie.split(";").map(cookie => cookie.trim());
    return cookies.some(cookie => cookie.includes("oomol-signed-in"));
  };

  useEffect(() => {
    const signedInCheck = async () => {
      // FIXME: 客户端执行删除账户后，网页上的 cookie 还未清除，所以这里会一直判断为已登录
      if (isSignedIn()) {
        try {
          if (desktop === "oomol-desktop") {
            navigate(RoutePath.AppLauncher);
          } else {
            navigate(RoutePath.Overview);
          }
        } catch (error) {
          console.error("error", error);
        }
      } else {
        const redirectURL =
          desktop === "oomol-desktop"
            ? `${OOMOL_SERVER_SIGNIN_AUTH.GITHUB_REDIRECT}?redirect=https://console.oomol.dev/launcher`
            : OOMOL_SERVER_SIGNIN_AUTH.GITHUB_REDIRECT;

        window.open(redirectURL, "_self");
      }
    };

    // OPTIMIZE: 临时方案开发环境下直接跳转到 Overview 页面
    if (process.env.NODE_ENV === "development") {
      navigate(RoutePath.Overview);
    }

    signedInCheck();
  }, [isSignedIn]);

  return (
    <div className={styles.loading}>
      <Spin size="large" />
    </div>
  );
};

export default Signin;
