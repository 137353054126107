import styles from "./ProjectSettings.module.scss";

import { Card } from "antd";

export const ProjectSettings = () => {
  return (
    <div>
      <Card title="Project Name" className={styles.card}>
        Used to identify your Project on the Dashboard, Vercel CLI, and in the
        URL of your Deployments.
      </Card>
      <Card title="Image Version" className={styles.card}>
        Used to identify your Project on the Dashboard, Vercel CLI, and in the
        URL of your Deployments.
      </Card>
      <Card title="Delete Project">
        The project will be permanently deleted, including its deployments and
        domains. This action is irreversible and can not be undone.
      </Card>
    </div>
  );
};
