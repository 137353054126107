import { message } from "antd";

import { isServerRequestError } from "../oomol-server-api/error";

// import { t } from "@oomol/i18n";

export const errorTips = (error: unknown): void => {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  if (isServerRequestError(error)) {
    if (error.errorMessage) {
      void message.error({
        content: `${error.errorMessage}`,
        key: error.errorCode,
      });
    } else {
      void message.error({
        // content: t("error-code-tips", { code: `${error.errorCode}` }),
        // TODO: i18n
        content: `error-code-tips, code: ${error.errorCode}`,
        key: error.errorCode,
      });
    }
  } else if ((error as Error).message) {
    const { message: content, message: key } = error as Error;
    void message.error({ content, key });
  } else {
    void message.error({ content: "unknown-error", key: "unknown" });
  }
};
