// export const OOMOL_SERVER_BASE_URL = process.env.OOMOL_SERVER_BASE_URL as string;
export const OOMOL_SERVER_BASE_URL = "https://api.oomol.dev";

export const OOMOL_SERVER_USER_V1_BASE_PATH = "v1";
export const OOMOL_SERVER_USER_V1 = {
  AUTH: `${OOMOL_SERVER_USER_V1_BASE_PATH}/auth`,
  USERS: `${OOMOL_SERVER_USER_V1_BASE_PATH}/users`,
  EMAIL: `${OOMOL_SERVER_USER_V1_BASE_PATH}/email`,
};

export const OOMOL_SERVER_SIGNIN_AUTH = {
  GITHUB_REDIRECT: `${OOMOL_SERVER_BASE_URL}/${OOMOL_SERVER_USER_V1.AUTH}/redirect`,
} as const;
