// import type { AppContext } from "./app";
import type { ReadonlyVal } from "value-enhancer";

import { createRoot } from "react-dom/client";
import { val, unwrap, from } from "value-enhancer";

import { ConsoleHome } from "./app";
import { Appearance } from "./constants";

// 填入真实实现

const appearance$ = val(Appearance.Auto);

const darkMode$ = unwrap(appearance$, mapAppearanceToDarkMode);
darkMode$.subscribe(darkMode => {
  document.documentElement.style.colorScheme = darkMode ? "dark" : "light";
});

const localeLang$ = val<string | undefined>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appContext: any = {
  darkMode$,
};

const root = createRoot(document.getElementById("root")!);
root.render(
  <ConsoleHome appContext={appContext} localeLang$={localeLang$}></ConsoleHome>
);

function mapAppearanceToDarkMode(
  appearance: Appearance
): ReadonlyVal<boolean> | boolean {
  if (appearance === "auto") {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    return from(
      () => (prefersDark ? prefersDark.matches : false),
      notify => {
        prefersDark.addEventListener("change", notify);
        return () => prefersDark.removeEventListener("change", notify);
      }
    );
  } else {
    return appearance === "dark";
  }
}
