import { Table } from "antd";

const dataSource = [
  {
    key: "1",
    detail: "胡彦斌",
    type: 32,
    time: "西湖区湖底公园1号",
    amount: 100,
  },
  {
    key: "2",
    detail: "胡彦斌",
    type: 32,
    time: "西湖区湖底公园1号",
    amount: 100,
  },
];

const columns = [
  {
    title: "交易详情",
    dataIndex: "detail",
    key: "detail",
  },
  {
    title: "交易类型",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "交易时间",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "交易金额",
    dataIndex: "amount",
    key: "amount",
  },
];

export const BillingHistory = () => {
  return <Table dataSource={dataSource} columns={columns} />;
};
