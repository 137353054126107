import styles from "./AppLauncher.module.scss";

import type { FC } from "react";

import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthID, getUserProfile, logout } from "~/oomol-server-api";

import { RoutePath } from "../constants";

const AppLauncher: FC = () => {
  const navigate = useNavigate();

  // TODO: 从 store 获取 initialState,
  const [email, setEmail] = useState("");
  const [authID, setAuthID] = useState<string>("");

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userInfo = await getUserProfile();
        const { authID } = await getAuthID();
        setAuthID(authID);
        setEmail(userInfo.email);
      } catch (e) {
        navigate(RoutePath.SigninWithDeskTop);
      }
    };
    void getUserInfo();
  }, []);

  const signInWithAnotherAccount = async () => {
    await logout();
    navigate("/signin/oomol-desktop");
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Sign in as {email}</h1>
      <Button
        target="_blank"
        rootClassName={styles.button}
        type="primary"
        ghost={true}
        // TODO: Console 的主页暂时还没有用，所以先导航到 hub 页面
        onClick={() => {
          window.location.href = `oomol://signin?authID=${authID}`;
          window.open("https://hub.oomol.dev/", "_self");
        }}
      >
        <p className={styles.buttonText}>
          Open Desktop and jump to the user page
        </p>
      </Button>
      <div className={styles.subTextBox}>
        <p className={styles.subText}>Not {email} ?</p>
        <a className={styles.link} onClick={signInWithAnotherAccount}>
          Sign in with another account
        </a>
      </div>
    </div>
  );
};

export default AppLauncher;
