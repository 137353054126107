import styles from "./SideNav.module.scss";

import type { MenuProps } from "antd";
import type { UIMatch } from "react-router-dom";

import {
  BranchesOutlined,
  DashboardOutlined,
  DollarOutlined,
  // FireOutlined,
  // GlobalOutlined,
  // SafetyOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useCallback, useMemo } from "react";
import { useLocation, useMatches, useNavigate } from "react-router-dom";
import { useTranslate } from "val-i18n-react";

type MenuItem = Required<MenuProps>["items"][number];
import { RoutePath } from "../constants";

export const SideNav = () => {
  const t = useTranslate();
  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    return {
      label,
      key,
      icon,
      children,
      type,
    } as MenuItem;
  };

  const items = useMemo<MenuItem[]>(
    () => [
      getItem(t("nav.overview"), RoutePath.Overview, <DashboardOutlined />),
      getItem(t("nav.project"), RoutePath.Project, <BranchesOutlined />),
      // getItem(t("nav.activity"), RoutePath.Activity, <FireOutlined />),
      // getItem(t("nav.domains"), RoutePath.Domains, <GlobalOutlined />),
      // getItem(t("nav.security"), RoutePath.Security, <SafetyOutlined />, [
      //   getItem(t("nav.security-log"), RoutePath.SecurityLog),
      //   getItem("账户安全", RoutePath.AccountSecurity),
      // ]),
      getItem(t("nav.billing"), RoutePath.Billing, <DollarOutlined />, [
        getItem("历史账单", RoutePath.BillingHistory),
        getItem("支付记录", RoutePath.PayHistory),
      ]),
    ],
    [t]
  );

  const location = useLocation();
  const matches = useMatches() as UIMatch<unknown, { crumb: string }>[];
  const navigate = useNavigate();

  const onSelect = useCallback(
    (item: { key: string }) => navigate(item.key),
    [navigate]
  );

  const selectedPath = useMemo(() => {
    if (location.pathname.startsWith(RoutePath.Project)) {
      return RoutePath.Project;
    }
    return location.pathname;
  }, [location.pathname]);

  return (
    <Menu
      className={styles.menu}
      items={items}
      defaultOpenKeys={[matches[1].pathname]}
      mode={"inline"}
      selectedKeys={[selectedPath]}
      onSelect={onSelect}
    />
  );
};
