import type { AppContext as NotificationContext } from "../components/UserNotification";
import type { ReadonlyVal } from "value-enhancer";

import { useMemo } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { useTranslate } from "val-i18n-react";

import { Activity } from "./Activity";
import AppLauncher from "./AppLauncher";
import { BillingHistory } from "./Billing/BillingHistory";
import { PayHistory } from "./Billing/PayHistory";
import { RoutePath } from "./constants";
import { Domains } from "./Domains";
import { HomeRoot } from "./HomeRoot";
import { Overview } from "./Overview";
import { Project } from "./Project";
import { ProjectDetail } from "./ProjectDetail";
import { AccountSecurity } from "./Security/AccountSecurity";
import { SecurityLog } from "./Security/SecurityLog";
import Signin from "./Signin";

export type AppContext = {
  darkMode$: ReadonlyVal<boolean>;
} & NotificationContext;

export const Routes = () => {
  const t = useTranslate();
  const createRouter = () =>
    createBrowserRouter([
      {
        path: RoutePath.Root,
        element: <HomeRoot />,
        children: [
          {
            path: RoutePath.Root,
            element: <Navigate to={RoutePath.Overview} replace />,
          },
          {
            path: RoutePath.Overview,
            element: <Overview />,
            handle: { crumb: "nav.overview" },
          },
          {
            path: RoutePath.Project,
            handle: { crumb: "nav.project" },
            children: [
              {
                path: RoutePath.Project,
                element: <Project />,
              },
              {
                path: RoutePath.ProjectDetail,
                element: <ProjectDetail />,
                handle: { crumb: "nav.project-detail" },
              },
            ],
          },
          {
            path: RoutePath.Activity,
            element: <Activity />,
            handle: { crumb: "nav.activity" },
          },
          {
            path: RoutePath.Domains,
            element: <Domains />,
            handle: { crumb: "nav.domains" },
          },
          {
            path: RoutePath.Security,
            handle: { crumb: "nav.security" },
            children: [
              {
                path: RoutePath.Security,
                element: <Navigate to={RoutePath.SecurityLog} replace />,
              },
              {
                path: RoutePath.SecurityLog,
                element: <SecurityLog />,
                handle: { crumb: "nav.security-log" },
              },
              {
                path: RoutePath.AccountSecurity,
                element: <AccountSecurity />,
                handle: { crumb: "nav.account-security" },
              },
            ],
          },
          {
            path: RoutePath.Billing,
            handle: { crumb: "nav.billing" },
            children: [
              {
                path: RoutePath.Billing,
                element: <Navigate to={RoutePath.BillingHistory} replace />,
              },
              {
                path: RoutePath.BillingHistory,
                element: <BillingHistory />,
                handle: { crumb: "nav.billing-history" },
              },
              {
                path: RoutePath.PayHistory,
                element: <PayHistory />,
                handle: { crumb: "nav.pay-history" },
              },
            ],
          },
        ],
      },
      {
        path: RoutePath.Signin,
        element: <Signin />,
        children: [
          {
            path: RoutePath.SigninWithDeskTop,
            element: <Signin />,
          },
        ],
      },
      {
        path: RoutePath.AppLauncher,
        element: <AppLauncher />,
      },
    ]);
  const router = useMemo(createRouter, [t]);

  return <RouterProvider router={router} />;
};
