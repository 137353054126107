import styles from "./Navigation.module.scss";

import type { UserAvatarProps } from "../UserAvatar";

import { UserAvatar } from "../UserAvatar";
import { UserNotification } from "../UserNotification";
import logo from "./images/logo.svg";

export const Navigation: React.FC<UserAvatarProps> = ({
  email,
  avatarURL,
  username,
  profileName,
  signout,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles["container-left"]}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.brand} />
      </div>
      <div className={styles["container-right"]}>
        <UserNotification />
        <UserAvatar
          signout={signout}
          avatarURL={avatarURL}
          username={username}
          email={email}
          profileName={profileName}
        />
      </div>
    </div>
  );
};
