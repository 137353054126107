import styles from "./Overview.module.scss";

import type { ProjectDataType } from "../Project/ProjectTable";
import type { EChartsOption } from "echarts";

import { BarChart } from "~/components/AnalyticsChart/BarChart";
import { LineChart } from "~/components/AnalyticsChart/LineChart";

import { ProjectTable } from "../Project/ProjectTable";

const data: ProjectDataType[] = [
  {
    key: "1",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "2",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "3",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "4",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "5",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
];

const barOptions: EChartsOption = {
  yAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  xAxis: {
    type: "value",
  },
  title: {
    left: "center",
    top: 12,
    text: "工作流使用次数排名",
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "bar",
      showBackground: true,
      itemStyle: {
        color: "#1890ff",
      },
      backgroundStyle: {
        color: "rgba(180, 180, 180, 0.2)",
      },
    },
  ],
};

const lineOptions: EChartsOption = {
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
  },
  title: {
    left: "center",
    top: 12,
    text: "使用次数趋势图",
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "line",
      smooth: true,
    },
  ],
};

export const Overview = () => {
  return (
    <div className={styles.container}>
      <div className={styles["chart-box"]}>
        <div className={styles["chart-container"]}>
          <LineChart lineOptions={lineOptions} />
        </div>
        <div className={styles["chart-container"]}>
          <BarChart barOptions={barOptions} />
        </div>
      </div>
      <div className={styles["List-box"]}>
        <ProjectTable tableData={data} />
      </div>
    </div>
  );
};
