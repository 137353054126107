import styles from "./ProjectTable.module.scss";

import type { TableProps } from "antd";

import {
  NodeIndexOutlined,
  GithubOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";

import { RoutePath } from "../constants";

export type ProjectDataType = {
  key: string;
  project: string;
  description: string;
  source: string;
  service: string;
  detail: string;
};

interface ProjectProps {
  tableData: Array<ProjectDataType>;
}

export const ProjectTable = (props: ProjectProps) => {
  const navigate = useNavigate();

  const getSourceName = (str: string) => {
    // 定义正则表达式模式
    const pattern = /https:\/\/github\.com\/(.*)/;

    // 使用正则表达式进行匹配
    const match = str.match(pattern);

    if (match && match.length > 1) {
      return match[1];
    }
  };

  const getServiceName = (url: string) => {
    const pattern = /https:\/\/(.*)/;

    const match = url.match(pattern);

    if (match && match.length > 1) {
      // console.log(match[1]); // 输出: playground.oomol.studio
      return match[1];
    }
  };
  const columns: TableProps<ProjectDataType>["columns"] = [
    {
      title: "项目名称",
      dataIndex: "project",
      key: "project",
      render: text => (
        <span>
          <Avatar
            shape="square"
            icon={<NodeIndexOutlined />}
            src="https://oomol-flows.oss-cn-hangzhou.aliyuncs.com/1715577938_ffmpeg.png"
            style={{ marginRight: 4 }}
          />
          <a style={{ fontSize: 13 }} href={text} target="_blank">
            {text}
          </a>
        </span>
      ),
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      width: 300,
      render: text => <span style={{ fontSize: 13 }}>{text}</span>,
    },
    {
      title: "源代码",
      dataIndex: "source",
      key: "source",
      render: text => (
        <a style={{ fontSize: 13 }} href={text} target="_blank">
          <span style={{ marginRight: 4 }}>
            <GithubOutlined />
          </span>
          {getSourceName(text)}
        </a>
      ),
    },
    {
      title: "服务地址",
      key: "service",
      dataIndex: "service",
      render: text => (
        <a href={text} target="_blank">
          <span style={{ marginRight: 4 }}>
            <LinkOutlined />
          </span>
          {getServiceName(text)}
        </a>
      ),
    },
    {
      title: "详情",
      key: "detail",
      dataIndex: "detail",
      width: 80,
      render: () => (
        <Button
          onClick={() => {
            navigate(RoutePath.ProjectDetail);
          }}
          size="small"
        >
          <span style={{ fontSize: 12 }}>详情</span>
        </Button>
      ),
    },
  ];

  return (
    <Table
      className={styles.box}
      columns={columns}
      dataSource={props.tableData}
    />
  );
};
