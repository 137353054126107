import styles from "./Project.module.scss";

import type { ProjectDataType } from "./ProjectTable";

import { ProjectTable } from "./ProjectTable";

const data: ProjectDataType[] = [
  {
    key: "1",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "2",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "3",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "4",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
  {
    key: "5",
    project: "oomol-example-flows",
    description:
      "The project showcases a sample workflow to help users familiarize themselves with OOMOL Studio.",
    source: "https://github.com/oomol-flows/oomol-example-flows",
    service: "https://playground.oomol.studio",
    detail: "oomol-example-flows",
  },
];
export const Project = () => {
  return (
    <div className={styles.box}>
      <ProjectTable tableData={data} />
    </div>
  );
};
