export enum RequestErrorCode {
  // ParamsInvalid 参数错误
  ParamsInvalidCode = "PARAMS_INVALID",

  // CredentialsInvalid 凭证无效
  CredentialsInvalidCode = "CREDENTIALS_INVALID",

  // CredentialsExpired 凭证已过期
  CredentialsExpiredCode = "CREDENTIALS_EXPIRED",

  // GithubCallbackErr github callback 携带的错误
  GithubCallbackErrCode = "GITHUB_CALLBACK_ERR",

  // AuthStateInvalid state 无效
  AuthStateInvalidCode = "AUTH_STATE_INVALID",

  // ServerNetwork 服务端对外发起的请求错误
  ServerNetworkCode = "SERVER_NETWORK",

  // InternalServerNetwork 请求内部微服务错误
  InternalServerNetworkCode = "INTERNAL_SERVER_NETWORK",

  // UserNotExist 用户不存在
  UserNotExistCode = "USER_NOT_EXIST",

  // UIDAlreadyExist UID 已存在
  UIDAlreadyExistCode = "UID_ALREADY_EXIST",

  // EmailNotExist 邮箱不存在
  EmailNotExistCode = "EMAIL_NOT_EXIST",

  // EmailAlreadyExist 邮箱已存在
  EmailAlreadyExistCode = "EMAIL_ALREADY_EXIST",

  // UsernameAlreadyExist 用户名已存在
  UsernameAlreadyExistCode = "USERNAME_ALREADY_EXIST",

  // GithubAlreadyExist Github 已被绑定
  GithubAlreadyExistCode = "GITHUB_ALREADY_EXIST",

  // GithubNotExist GithubUID 不存在
  GithubNotExistCode = "GITHUB_NOT_EXIST",

  // UsernameNotExist 用户名不存在
  UsernameNotExistCode = "USERNAME_NOT_EXIST",

  // PasswordIncorrect 密码错误
  PasswordIncorrectCode = "PASSWORD_INCORRECT",

  // Internal 内部错误
  InternalCode = "INTERNAL",

  // CodeInvalid code 无效
  CodeInvalidCode = "CODE_INVALID",
}
// TODO: i18n @Cheerego7
export const RequestErrorMessage = {
  [RequestErrorCode.ParamsInvalidCode]: "参数错误",
  [RequestErrorCode.CredentialsInvalidCode]: "凭证无效",
  [RequestErrorCode.CredentialsExpiredCode]: "凭证已过期",
  [RequestErrorCode.GithubCallbackErrCode]: "github callback 携带的错误",
  [RequestErrorCode.AuthStateInvalidCode]: "state 无效",
  [RequestErrorCode.ServerNetworkCode]: "服务端对外发起的请求错误",
  [RequestErrorCode.InternalServerNetworkCode]: "请求内部微服务错误",
  [RequestErrorCode.UserNotExistCode]: "用户不存在",
  [RequestErrorCode.UIDAlreadyExistCode]: "UID 已存在",
  [RequestErrorCode.EmailNotExistCode]: "邮箱不存在",
  [RequestErrorCode.EmailAlreadyExistCode]: "邮箱已存在",
  [RequestErrorCode.UsernameAlreadyExistCode]: "用户名已存在",
  [RequestErrorCode.GithubAlreadyExistCode]: "Github 已被绑定",
  [RequestErrorCode.GithubNotExistCode]: "GithubUID 不存在",
  [RequestErrorCode.UsernameNotExistCode]: "用户名不存在",
  [RequestErrorCode.PasswordIncorrectCode]: "密码错误",
  [RequestErrorCode.InternalCode]: "内部错误",
  [RequestErrorCode.CodeInvalidCode]: "code 无效",
};

export class ServerRequestError extends Error {
  public errorCode: RequestErrorCode;
  public errorMessage: string;

  public constructor(errorCode: RequestErrorCode) {
    super(`request error: ${errorCode}`);
    this.name = "ServerRequestError";
    this.errorCode = errorCode;
    this.errorMessage = RequestErrorMessage[errorCode];
  }
}

export function isServerRequestError(
  error: unknown
): error is ServerRequestError {
  return error instanceof Error && Object.hasOwn(error, "errorCode");
}
