import styles from "./ProjectOverview.module.scss";

import type { CollapseProps } from "antd";
import type { EChartsOption } from "echarts/types/dist/echarts";

import { NodeIndexOutlined, RedoOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Collapse } from "antd";
import { LineChart } from "~/components/AnalyticsChart/LineChart";

import flows from "./flows_ex.png";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const lineOptions: EChartsOption = {
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
  },
  title: {
    left: "center",
    top: 12,
    text: "使用次数趋势图",
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "line",
      smooth: true,
    },
  ],
};

export const ProjectOverview = () => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const items2: CollapseProps["items"] = [
    {
      key: "1",
      label: "Building",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "Deployment Summary",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "Running Checks",
      children: <p>{text}</p>,
    },
    {
      key: "4",
      label: "Assigning Custom Domains",
      children: <p>{text}</p>,
    },
  ];
  return (
    <div className={styles.box}>
      <div className={styles.nav}>
        <div className={styles["nav-left"]}>
          <div className={styles.logo}>
            <Avatar
              shape="square"
              src={
                "https://oomol-flows.oss-cn-hangzhou.aliyuncs.com/1715240911_agricultural_data.png"
              }
              size={72}
            />
          </div>
          <div>
            <div className={styles.title}>cloud OCR</div>
            <div className={styles.description}>
              Use Alibaba Cloud API to perform image recognition and extract
              text from images.
            </div>
          </div>
        </div>
        <div>
          <Button icon={<RedoOutlined />} className={styles["nav-btn"]}>
            Rebuild
          </Button>
          <Button icon={<NodeIndexOutlined />} className={styles["nav-btn"]}>
            Visit
          </Button>
        </div>
      </div>
      <Card className={styles.project}>
        <div className={styles.container}>
          <div className={styles.flows}>
            <div className={styles["flows-inner"]}>
              <img src={flows} alt="flow" />
            </div>
          </div>
          <div className={styles.meta}>
            <div>
              <div className={styles["meta-title"]}>Project</div>
              <div>oomol-example-flows</div>
            </div>
            <div>
              <div className={styles["meta-title"]}>Playground</div>
              <div>https://playground.oomol.studio/whisper-cm96078m</div>
            </div>
            <div className={styles.status}>
              <div className={styles["status-cell"]}>
                <div className={styles["meta-title"]}>Status</div>
                <div className={styles["status-container"]}>
                  <div className={styles["status-point"]} />
                  <div>Ready</div>
                </div>
              </div>
              <div className={styles["status-cell"]}>
                <div className={styles["meta-title"]}>Created</div>
                <div>1d ago by crimx</div>
              </div>
            </div>
            <div>
              <div className={styles["meta-title"]}>Source</div>
              <div>oomol-flows/oomol-example-flows</div>
            </div>
          </div>
        </div>
      </Card>
      <div className={styles["chart-container"]}>
        <LineChart lineOptions={lineOptions} />
      </div>
      <div className={styles["log-container"]}>
        <Collapse items={items2} defaultActiveKey={["1"]} onChange={onChange} />
      </div>
    </div>
  );
};
